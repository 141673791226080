html, body, #root {
    height: auto;
    min-width: 360px;
    background-color: #f6f8f8;
    position: relative;
}

body {
    width: 100%;
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.content {
    height: 100%;
    width: 100%;
    text-align: center;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100px;
    flex-direction: column;
}

table th {
    color: #4d4d4d;
}

table td {
    color: #777777;
}

.session-section {
    display: block;
    width: 30%;
    min-width: 270px;
    text-align: center;
    margin: auto;
}

.container {
    width: 35%;
    border: 1px solid #ccc9c9;
    display: block;
    border-radius: 7px;
    min-width: 320px;
    min-height: 200px;
    margin: auto;
    background: #fffcf5;
    box-shadow: 0 0 4px rgb(255 215 3 / 40%)
}

.current-user-container {
    width: 30%;
    border: 1px solid #ccc9c9;
    display: block;
    border-radius: 6px;
    min-width: 270px;
    margin: 20px 0;
    padding: 5px 0;
    background: #f4fdfa;
    box-shadow: 0 0 4px rgb(45 255 0 / 25%)
}

.user-name {
    font-weight: bold;
}

.green-border {
    border-color: #bff1c1;
}

.orange-border {
    border-color: #f1e0bf;
}

.vertical-center {
    margin: 0;
    position: relative;
}

.form-box {
    width: 100%;
    height: 100%;
    position: relative;
}

.div-10 input {
    display: inline-block;
    width: 100%;
    min-width: 102px;
    margin: 0 7px;
    border: none;
    border-radius: 9px;
    font-size: 17px;
    padding: 15px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.div-in-form {
    max-width: 300px;
}

.share-btn {
    width: 70% !important;
    min-width: 245px !important;
}

.click:hover {
    opacity: 0.9;
}

.click:focus {
    filter: brightness(80%);
    color: white;
}

.click {
    color: white;
    background-color: #25b7a6;
}

.click-join {
    background-color: #5ca0d9;
}

.click-home {
    margin: 20px 0;
    border: 2px solid #ebebeb;
    border-radius: 5px;
    font-size: 17px;
    padding: 5px 10px;
    width: 100%;
    color: white;
    background-color: #04AA6D;
}

.no-session {
    margin-top: 20px;
}

.disabled {
    background-color: #808080;
}

.disabled:hover {
    background-color: #808080;
}

.terminate:hover {
    background-color: #f51010;
}

.terminate:focus {
    background-color: #c70202;
}

.terminate {
    display: inline-block;
    margin: 0 7px;
    border: 2px solid #ebebeb;
    border-radius: 5px;
    font-size: 15px;
    padding: 7px 15px;
    background-color: #FC4141FF;
    color: white;
}

.copy {
    color: #8f9193;
    font-size: smaller;
}

.copied {
    color: #059862;
    font-size: smaller;
}

.text-btw-btn {
    margin: 20px 0;
    color: #797676;
    font-size: smaller;
    font-weight: 600;
}

.session-code {
    font-size: larger;
    font-weight: 800;
    color: black;
}

.create-session-container {
    min-height: 100px;
    height: 100px;
    margin-bottom: 20px;
}

.full-height {
    height: 100%;
}

.div {
    height: auto;
    background-color: #f6f8f8;
    display: flex;
    flex-direction: column;
}

.div-2 {
    align-items: flex-start;
    background-color: #0D9488;
    display: flex;
    padding: 16px 20px;
    gap: 12px;
}

@media (max-width: 991px) {
    .div-2 {
        max-width: 100%;
    }
}

.logo {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
}

@media (max-width: 991px) {
    .img {
        margin-left: 10px;
    }
}

.div-3 {
    border-radius: 6px;
    border: 1px solid #25b7a633;
    grid-gap: 11px;
    gap: 11px;
    background-color: #e1f2f0;
    align-self: end;
    width: 343px;
    max-width: 100%;
    margin: 92px 32px 0 0;
    display: flex;
    position: fixed;
    min-width: 10em;
    padding: 16px;
    transition: 0.2s opacity;
    z-index: 4;
}

.vis {
    opacity: 1;
}

.hid {
    opacity: 0;
}

.container-x {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: #2c2c2c;
    z-index: 3;
}

.vis-container-x {
    opacity: 0;
    animation: fadeIn forwards 1s;
    visibility: visible;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    30%{
        opacity: 0.4;
    }
    90% {
        opacity: 0.4;
    }
    100% {
        opacity: 0;
    }
}

.hid-container-x {
    visibility: hidden;
}

.div-no-toast {
    align-self: end;
    display: flex;
    position: relative;
    width: 343px;
    max-width: 100%;
    gap: 11px;
    margin: 32px 32px 0 0;
    padding: 16px;
    height: 2%;
}

@media (max-width: 991px) {
    .div-3, .div-no-toast {
        margin-right: 10px;
        justify-content: center;
    }
}

.img-2 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}

.div-4 {
    color: #1a3b47;
    letter-spacing: -0.4px;
    flex-grow: 1;
    flex-basis: auto;
    font: 400 16px/24px Inter, sans-serif;
}

.img-3 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
    max-width: 100%;
}

.div-5 {
    align-self: center;
    width: 100%;
    max-width: 1142px;
    margin: 44px 0 168px;
}

@media (max-width: 991px) {
    .div-5 {
        max-width: 100%;
        margin: 40px 0;
    }
}

.div-6 {
    gap: 30px;
    display: flex;
}

@media (max-width: 991px) {
    .div-6 {
        flex-direction: column;
        align-items: stretch;
        gap: 0;
    }
}

@media (max-width: 1098px) {
    .div-6 {
        margin: 0 10px;
    }
}

.column {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 39%;
    margin-left: 0;
}

@media (max-width: 991px) {
    .column {
        width: 100%;
    }
}

.div-7 {
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 0 32px 36px;
}

@media (max-width: 991px) {
    .div-7 {
        max-width: 100%;
        margin-top: 31px;
        padding: 0 20px 20px 20px;
    }
}

.img-4 {
    aspect-ratio: 1.66;
    object-fit: contain;
    object-position: center;
    width: 96px;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    overflow: hidden;
    max-width: 100%;
}

.div-8 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
}

.div-9 {
    color: #0f766e;
    text-align: center;
    font: 600 24px/31px Inter, sans-serif;
}

.div-10 {
    color: #1a3b47;
    text-align: center;
    letter-spacing: -0.4px;
    margin-top: 8px;
    font: 500 16px/24px Inter, sans-serif;
}

.qr-code {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 218px;
    overflow: hidden;
    margin-top: 8px;
    max-width: 100%;
}

.div-11 {
    align-self: stretch;
    display: flex;
    margin-top: 32px;
    gap: 6px;
}

@media (max-width: 991px) {
    .div-11 {
        justify-content: center;
    }
}

.img-6 {
    aspect-ratio: 30;
    object-fit: contain;
    object-position: center;
    width: 30px;
    stroke-width: 1px;
    stroke: #e3e6e9;
    overflow: hidden;
    align-self: center;
    max-width: 100%;
    margin: auto 0;
}

.div-12 {
    color: #1a3b47;
    text-align: center;
    letter-spacing: -0.4px;
    flex-grow: 1;
    flex-basis: auto;
    font: 400 14px/21px Inter, sans-serif;
}

.div-13 {
    align-items: center;
    align-self: stretch;
    border-radius: 6px;
    background-color: #e1f2f0;
    display: flex;
    margin-top: 32px;
    justify-content: space-between;
    gap: 12px;
    padding: 7px 7px 7px 15px;
    border: 1px solid #25b7a633;
}

.div-14 {
    color: #000;
    letter-spacing: -0.4px;
    flex-grow: 1;
    flex-basis: auto;
    margin: auto 0;
    font: 400 16px/24px Inter, sans-serif;
}

.img-8 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 42px;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    align-self: stretch;
    max-width: 100%;
}

.column-2 {
    display: flex;
    flex-direction: column;
    line-height: normal;
    width: 61%;
    margin-left: 20px;
}

@media (max-width: 991px) {
    .column-2 {
        width: 100%;
        margin-left: 0;
    }
}

.div-15 {
    border-radius: 16px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.08);
    background-color: #fff;
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    padding: 32px;
}

@media (max-width: 991px) {
    .div-15 {
        max-width: 100%;
        margin-top: 30px;
        padding: 20px;
    }
}

.div-16 {
    color: #0f766e;
    letter-spacing: -0.4px;
    font: 600 20px/21px Inter, sans-serif;
}

@media (max-width: 991px) {
    .div-16 {
        max-width: 90%;
    }
}

.div-17 {
    color: #1a3b47;
    letter-spacing: -0.39px;
    margin-top: 8px;
    font: 400 16px/24px Inter, sans-serif;
}

@media (max-width: 991px) {
    .div-17 {
        max-width: 90%;
    }
}

.div-18 {
    display: flex;
    margin-top: 25px;
    gap: 15px;
}

@media (max-width: 991px) {
    .div-18 {
        max-width: 90%;
        flex-wrap: wrap;
    }
}

.div-19 {
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #0f766e;
    display: flex;
    aspect-ratio: 1;
    flex-direction: column;
    width: 40px;
    height: 40px;
}

.img-9 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
}

.div-20 {
    color: #1a3b47;
    letter-spacing: -0.4px;
    align-self: center;
    white-space: initial;
    margin: auto 0;
    font: 400 16px/24px Inter, sans-serif;
}

@media (max-width: 991px) {
    .div-20 {
        max-width: 90%;
    }
}

.div-21 {
    display: flex;
    margin-top: 25px;
    gap: 15px;
}

@media (max-width: 991px) {
    .div-21 {
        max-width: 90%;
        flex-wrap: wrap;
    }
}

.div-22 {
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    background-color: #0f766e;
    display: flex;
    aspect-ratio: 1;
    flex-direction: column;
    width: 40px;
    height: 40px;
}

.img-10 {
    aspect-ratio: 1;
    object-fit: contain;
    object-position: center;
    width: 24px;
    overflow: hidden;
}

.div-23 {
    color: #1a3b47;
    letter-spacing: -0.4px;
    align-self: center;
    white-space: initial;
    margin: auto 0;
    font: 400 16px/24px Inter, sans-serif;
}

@media (max-width: 991px) {
    .div-23 {
        max-width: 90%;
    }
}

.video-styles {
    height: 100vh;
    width: auto;
    max-width: 70%;
}

.icon-big-start {
    border-radius: 100px;
    background: #0F766E;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    top: -59px;
    display: flex;
    width: 38px;
    height: 38px;
    padding: 29px;
}

@media (max-width: 991px) {
    .icon-big-start {
        top: -38px;
    }
}

.icon-big {
    border-radius: 100px;
    background: #0F766E;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
    position: relative;
    top: -38px;
    display: flex;
    width: 38px;
    height: 38px;
    padding: 29px;
    z-index: 1;
}

.copy-icon {
    display: flex;
    width: 25px;
    height: 25px;
    padding: 9px;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background: #25B7A6;
}

.wifi-icon {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #0F766E;
}

.mute-icon {
    display: flex;
    padding: 8px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 100px;
    background: #0F766E;
}

.vector {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 6px;
    align-self: stretch;
}
